import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import {
  GridBlock,
  GridColumn,
  HTMLContent,
  GoogleMap,
} from '../components/ui';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import PostMeta from '../components/PostMeta';
import ModularBlocks from '../components/ModularBlocks';
import PostCards from '../components/PostCards';

const EventSingleTemplate = ({
  data: {
    datoCmsEventSingle: {
      seoMetaTags,
      title,
      bannerImage,
      dateText,
      dateAttribute,
      location,
      description,
      modularBlocks,
    },
    allDatoCmsEventSingle: { nodes: otherEvents },
  },
}) => {
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          title={title}
          date={{
            text: dateText,
            attribute: dateAttribute,
          }}
          image={bannerImage}
        />
        <GridBlock columns="3" gap="40px" margins="true">
          <GridColumn span="2">
            <HTMLContent content={'<h2>Description</h2>' + description} />
            <GoogleMap location={location} />
          </GridColumn>
          <GridColumn span="1">
            <PostMeta
              date={{
                text: dateText,
                attribute: dateAttribute,
              }}
              location={location}
            />
          </GridColumn>
        </GridBlock>
        <ModularBlocks items={modularBlocks} />
      </main>
      {otherEvents.length > 0 && (
        <PostCards
          heading={{
            text: 'Other Events',
          }}
          items={otherEvents}
          baseSlug="/events/"
          link={{
            slug: '/events',
            text: 'View all Events',
          }}
          margins={true}
        />
      )}
    </Layout>
  );
};

export const EventSingleTemplateQuery = graphql`
  query EventSingleTemplateQuery($id: String!, $todaysDate: Date!) {
    datoCmsEventSingle(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        fluid(
          maxHeight: 380
          maxWidth: 1600
          imgixParams: { auto: "compress", fit: "crop", h: "380", w: "1600" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      dateText: date(formatString: "DD MMMM YYYY")
      dateAttribute: date(formatString: "YYYY-MM-DD")
      location
      description
      modularBlocks {
        ... on DatoCmsAccordionModularBlock {
          model {
            apiKey
          }
          instance {
            heading
            accordion {
              id
              heading
              text
            }
          }
        }
        ... on DatoCmsContactFormModularBlock {
          id
          model {
            apiKey
          }
          heading
          headingBackgroundText
        }
        ... on DatoCmsGalleryModularBlock {
          id
          model {
            apiKey
          }
          heading
          headingBackgroundText
          images {
            alt
            fluid(
              maxHeight: 580
              maxWidth: 940
              imgixParams: { auto: "compress", fit: "crop", h: "580", w: "940" }
            ) {
              ...GatsbyDatoCmsFluid
            }
            title
          }
        }
      }
    }
    allDatoCmsEventSingle(
      filter: { date: { gte: $todaysDate }, id: { ne: $id } }
      limit: 3
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          fluid(
            maxHeight: 280
            maxWidth: 480
            imgixParams: { auto: "compress", fit: "crop", h: "280", w: "480" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
        }
        dateText: date(formatString: "DD MMMM YYYY")
        dateAttribute: date(formatString: "YYYY-MM-DD")
      }
    }
  }
`;

export default EventSingleTemplate;
